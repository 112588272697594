<template>
  <section class="col-7">
    <modal-loading :is-loading="loading" :message="loadingMessage" />
    <h2 class="mb-10">ウェブサイトの設定</h2>

    <div>
      <v-form>
        <v-text-field
          v-model="setting.title"
          label="サイトタイトル"
          type="text"
          outlined
        />
        <v-textarea
          v-model="setting.description"
          label="概要"
          type="text"
          outlined
          hint="※ 100〜120文字程度が適切です。"
          persistent-hint
          rows="3"
        />
      </v-form>
    </div>

    <div class="mb-8">
      <div class="subtitle-1 mb-2">アイコン画像</div>

      <v-card class="d-inline-block mx-auto" outlined>
        <v-col cols="auto">
          <section
            v-if="!iconPreview && !setting.icon"
            class="icons__upload_area"
            @dragleave.prevent
            @dragover.prevent
            @drop.prevent="onFileChange"
          >
            <p class="mb-11">
              アップロードしたい写真をここにドロップ<br />
              (.png, .jpgに対応しています)
            </p>
            <p class="mb-12">または</p>
            <v-btn rounded outlined @click="selectFile"> ファイルを選択 </v-btn>
          </section>

          <input
            ref="file"
            style="display: none"
            type="file"
            @change="onFileChange"
          />

          <v-img
            v-if="iconPreview"
            :src="iconPreview"
            height="300"
            width="300"
          />
          <v-img
            v-if="!iconPreview && setting.icon"
            :src="setting.icon"
            height="300"
            width="300"
          />

          <v-card-actions>
            <v-btn
              v-if="setting.icon"
              color="primary"
              outlined
              @click="selectFile"
            >
              アイコン画像を編集
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="iconPreview || (backupIcon && !setting.icon)"
              outlined
              @click="cancelEditIcon"
            >
              キャンセル
            </v-btn>
            <v-btn v-if="setting.icon && !iconPreview" icon @click="deleteIcon">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-card>
    </div>

    <div>
      <div class="subtitle-1 mb-2">1ページあたりのデータ表示件数</div>
      <v-select v-model="setting.perPage" outlined :items="perPageList" />
    </div>

    <div>
      <div class="subtitle-1 mb-2">地図表示でのデフォルト位置情報</div>
      <v-text-field
        :value="keyword"
        label="緯度経度の検索"
        type="search"
        outlined
        clearable
        prepend-inner-icon="mdi-magnify"
        hint="場所の名称や住所で緯度経度の検索が可能です。"
        persistent-hint
        @change="(value) => (keyword = value)"
      />
      <v-text-field
        v-model="setting.latitude"
        label="緯度"
        :error-messages="errorMessages.latitude"
        type="text"
        outlined
        hint="※ 小数点以下7桁以降は切り捨てられます。"
        persistent-hint
        @change="
          (n) => {
            // 数値以外の場合はそのまま、保存時にエラーメッセージを出す
            setting.latitude = !Number.isNaN(round(Number(n)))
              ? round(Number(n))
              : n;
          }
        "
      />
      <v-text-field
        v-model="setting.longitude"
        label="経度"
        :error-messages="errorMessages.longitude"
        type="text"
        outlined
        hint="※ 小数点以下7桁以降は切り捨てられます。"
        persistent-hint
        @change="
          (n) => {
            // 数値以外の場合はそのまま、保存時にエラーメッセージを出す
            setting.longitude = !Number.isNaN(round(Number(n)))
              ? round(Number(n))
              : n;
          }
        "
      />
    </div>
    <div class="mb-8">
      <div id="map" style="height: 420px">
        <GmapMap
          ref="mapRef"
          :center="position"
          :zoom="setting.zoomLevel"
          :options="{ draggableCursor: 'default' }"
          style="width: 100%; height: 100%"
          @click="getLatLng"
        >
          <!-- クリックまたは緯度経度で示した場所に赤いマーカーを置く -->
          <GmapMarker
            v-if="
              setting.latitude &&
              setting.longitude &&
              !Number.isNaN(round(Number(setting.latitude))) &&
              !Number.isNaN(round(Number(setting.longitude)))
            "
            :position="position"
            :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
          />
        </GmapMap>
      </div>
    </div>

    <div class="mb-8">
      <div class="subtitle-1 mb-2">地図表示でのデフォルトズームレベル</div>
      <v-text-field
        v-model.number="setting.zoomLevel"
        label="ズームレベル"
        type="number"
        outlined
        hint="※ 設定できるズームレベルは1〜21です。"
        persistent-hint
      />
    </div>

    <hr class="mt-8 mb-4" />

    <v-col>
      <v-row justify="end">
        <v-btn color="primary" large @click="save"> 保存 </v-btn>
      </v-row>
    </v-col>
  </section>
</template>

<script>
import { getData, updateData } from "../../axios";
import ModalLoading from "../../components/ModalLoading.vue";
import * as VueGoogleMaps from "vue2-google-maps";

export default {
  name: "Index",

  components: {
    ModalLoading,
  },

  data() {
    return {
      loading: false,
      loadingMessage: "",
      iconPreview: "",
      setting: {
        title: "",
        description: "",
        icon: "",
        perPage: 10,
        latitude: null,
        longitude: null,
        zoomLevel: 13,
      },
      backupIcon: "",
      perPageList: [10, 50, 100, 500],
      keyword: "",
      errorMessages: {
        latitude: "",
        longitude: "",
      },
      japaneseItems: {
        latitude: "緯度",
        longitude: "経度",
      },
    };
  },

  computed: {
    client() {
      return this.$store.getters["auth/client"].setting;
    },
    position() {
      if (
        this.setting.latitude &&
        this.setting.longitude &&
        !Number.isNaN(this.round(Number(this.setting.latitude))) &&
        !Number.isNaN(this.round(Number(this.setting.longitude)))
      ) {
        return {
          lat: this.round(Number(this.setting.latitude)),
          lng: this.round(Number(this.setting.longitude)),
        };
      } else {
        // 初期座標：東京駅
        return { lat: 35.681236, lng: 139.767124 };
      }
    },
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0;
    },
  },

  watch: {
    client() {
      const setting = this.$store.getters["auth/client"].setting;
      if (setting) {
        this.setting.title = setting.title || "";
        this.setting.description = setting.description || "";
        this.setting.icon = setting.icon || "";
        this.setting.perPage = Number(setting.perPage) || 10;
        this.backupIcon = setting.icon || "";
      }
    },
    keyword() {
      this.searchLatLng();
    },
  },

  created() {
    if (this.client) {
      const setting = this.$store.getters["auth/client"].setting;
      this.setting.title = setting.title || "";
      this.setting.description = setting.description || "";
      this.setting.icon = setting.icon || "";
      this.setting.perPage = Number(setting.perPage) || 10;
      this.setting.latitude = this.round(Number(setting.latitude)) || null;
      this.setting.longitude = this.round(Number(setting.longitude)) || null;
      this.setting.zoomLevel = Number(setting.zoomLevel) || 13;
      this.backupIcon = setting.icon || "";
    } else {
      getData("clients").then((res) => {
        this.setting.title = res.data.setting.title || "";
        this.setting.description = res.data.setting.description || "";
        this.setting.icon = res.data.setting.icon || "";
        this.setting.perPage = Number(res.data.setting.perPage) || 10;
        this.setting.latitude =
          this.round(Number(res.data.setting.latitude)) || null;
        this.setting.longitude =
          this.round(Number(res.data.setting.longitude)) || null;
        this.setting.zoomLevel = Number(res.data.setting.zoomLevel) || 13;
        this.backupIcon = res.data.setting.icon || "";
      });
    }
  },

  methods: {
    selectFile() {
      this.$refs.file.click();
    },
    reset() {
      this.iconPreview = "";
      this.setting.icon = "";
      this.$el.querySelector('input[type="file"]').value = null;
    },
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length === 0) {
        this.reset();
        return;
      }
      if (!files[0].type.match("image.*")) {
        alert("写真を選択してください。");
        this.reset();
        return;
      }
      this.showUploadImage(files[0]);
    },
    showUploadImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.iconPreview = reader.result;
        this.setting.icon = file;
      };
      reader.readAsDataURL(file);
    },
    // 共通整備項目で、経度、緯度は小数点以下6桁となっているため、統一する
    round(num) {
      return Math.floor(num * 1000000) / 1000000;
    },
    searchLatLng() {
      const maps = VueGoogleMaps.gmapApi().maps;
      const geocoder = maps.Geocoder.prototype;
      geocoder.geocode({ address: this.keyword }, (results, status) => {
        if (status === maps.GeocoderStatus.OK && results.length > 0) {
          this.setting.latitude = this.round(
            results[0].geometry.location.lat()
          );
          this.setting.longitude = this.round(
            results[0].geometry.location.lng()
          );
        }
      });
    },
    getLatLng(e) {
      this.setting.latitude = this.round(e.latLng.lat());
      this.setting.longitude = this.round(e.latLng.lng());
    },
    save() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "保存中...";
        const formData = new FormData();
        formData.append("title", this.setting.title);
        formData.append("description", this.setting.description);
        formData.append("icon", this.setting.icon);
        formData.append("perPage", this.setting.perPage);
        formData.append("latitude", this.setting.latitude);
        formData.append("longitude", this.setting.longitude);
        formData.append("zoomLevel", this.setting.zoomLevel);
        updateData("clients/" + this.$store.getters["auth/client"].id, formData)
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            this.$store.dispatch("auth/setClient");
            this.$store.dispatch("snackbar/setSnackbar", {
              message: "サイト設定を保存しました。",
              color: "success",
              timeout: 2000,
            });
          })
          .catch(() => {
            this.loading = false;
            this.loadingMessage = "";
          });
      }
    },
    deleteIcon() {
      this.reset();
    },
    cancelEditIcon() {
      this.iconPreview = "";
      this.setting.icon = this.backupIcon;
      this.$el.querySelector('input[type="file"]').value = null;
    },
    validate() {
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
      Object.keys(this.setting).forEach((key) => {
        this.checkNumber(key, ["latitude", "longitude"]);
      });
    },
    checkNumber(key, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          !Number.isFinite(Number(this.setting[key])) &&
          this.setting[key] !== ""
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は半角数字で記入して下さい。`;
        }
      });
    },
  },
};
</script>

<style scoped>
.lightbox {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0%,
    transparent 80px
  );
}
.icons__upload_area {
  width: 300px;
  height: 300px;
  padding: 4rem 1rem;
  text-align: center;
  border: 0.1rem dotted #bcbcbc;
}
</style>
